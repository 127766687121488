import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Layout from '../components/layout';
import SEO from '../components/SEO';
import sectionConductor from '../sections/section-conductor';

class CaseStudiesPage extends Component {
  render() {
    const { data, location, pageContext } = this.props;
    const { contentfulLayout: page } = data;

    const renderedSections = page.sections.map((section, i) => sectionConductor(section, i));

    const pageWrapClasses = classNames({
      'page': true,
      'page--case-studies': true, // just to avoid the /us, /nl etc
    });

    return (
      <div className={pageWrapClasses}>
        <Layout navbarIsInverted pageColorInverted pathname={location.pathname}>
          <SEO title={page.title} metadata={page.metadata} routeName="cases:list" />
          {renderedSections}
        </Layout>
      </div>
    );
  }
}

export default CaseStudiesPage;

export const pageQuery = graphql`
  query ($contentfulid: String!, $whatRegion: String!) {
    contentfulLayout(contentfulid: { eq: $contentfulid }, whatRegion: { eq: $whatRegion }) {
      title
      contentfulid
      url
      metadata {
        ...Metadata
      }
      ...AllSectionsFragment
    }
  }
`;

CaseStudiesPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
